/* eslint-disable @typescript-eslint/no-explicit-any */
function getLib<T = any>(prop: string): T {
  return (window as any)[prop] as T;
}
function loadLib<T = any>(src: string, prop: string): Promise<T> {
  return new Promise((resolve, reject) => {
    const scriptEl = document.createElement("script");
    Object.assign(scriptEl, {
      src,
      onerror: () => reject(new URIError(`Script ${src} failed to load.`)),
      onload: () => resolve(getLib<T>(prop)),
    });
    document.body.appendChild(scriptEl);
  });
}

/**
 * Loads an external script asynchronously
 *
 * @param url The script to load
 * @param windowProp Window property set by the library
 */
async function loadScript<T = any>(
  url: string,
  windowProp: string
): Promise<T> {
  return getLib<T>(windowProp) || loadLib<T>(url, windowProp);
}

/**
 * Downloads the supplied node as a PNG image. I
 * @param node The element to capture
 */
export async function downloadImage(node: HTMLElement): Promise<void> {
  try {
    const domtoimage = await loadScript(
      "https://cdnjs.cloudflare.com/ajax/libs/dom-to-image/2.6.0/dom-to-image.min.js",
      "domtoimage"
    );
    const saveAs = await loadScript(
      "https://cdnjs.cloudflare.com/ajax/libs/FileSaver.js/2.0.5/FileSaver.min.js",
      "saveAs"
    );
    const blob = await domtoimage.toPng(node);
    saveAs(blob, "", { type: "image/png" });
  } catch (e: any) {
    alert(`Couldn't save image: ${e.message}`);
  }
}
