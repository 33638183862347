/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { HTMLAttributes } from "react";
import Layout from "../components/Layout";
import Head from "../components/Head";
import Header from "../components/Header";
import MarkdownContent from "../components/MarkdownContent";
import { H2, H3 } from "../components/MarkdownElements";
import * as classNames from "./BrandingPage.module.css";
import cx from "classnames";
import { SiteMetadataQueryResult, SnippetQueryResult } from "../graphql";
import { downloadImage } from "../util/download-image";

const pageTitle = "Branding";

const AVATAR_URL = "/images/avatar.png";

const Avatar: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => (
  // eslint-disable-next-line
  <div
    className={cx(className, classNames.avatar)}
    onClick={(e): Promise<void> => downloadImage(e.currentTarget)}
    role="button"
    {...props}
  >
    <img className={classNames.avatarImage} src={AVATAR_URL} alt="" />
  </div>
);

const Teaser: React.FC<HTMLAttributes<HTMLDivElement> & { alt?: string }> = ({
  className,
  alt = "",
  ...props
}) => (
  <MarkdownContent
    brief={true}
    className={cx(className, classNames.teaser)}
    onClick={(e): Promise<void> => downloadImage(e.currentTarget)}
    role="button"
    {...props}
  >
    <div className={classNames.teaserText}>
      <img
        className={classNames.teaserImage}
        src={AVATAR_URL}
        style={{
          shapeOutside: `url(${AVATAR_URL})`,
        }}
        alt={alt}
      />
      <div className={classNames.teaserHeading}>Hi, I&rsquo;m Kara!</div>
      <H3 className={classNames.teaserSubhead}>A new look for a new me.</H3>
      <p>
        I&rsquo;m excited—and a bit terrified—to announce this new chapter.
        Thanks for helping me spread the word.
      </p>
      <p>
        My story continues at{" "}
        <a href="https://kararyli.net">https://kararyli.net</a>.
      </p>
    </div>
  </MarkdownContent>
);

export interface BrandingPageProps {
  query: SiteMetadataQueryResult & SnippetQueryResult;
}

const BrandingPage: React.FC<BrandingPageProps> = ({ query }) => (
  <Layout>
    <Head title={pageTitle} />
    <Header title={pageTitle} />
    <MarkdownContent html={query.file.childMdx.body}></MarkdownContent>
    <H2>Avatar</H2>
    <p>
      Avatars are square, but most sites these days present them either circular
      or with rounded corners.
    </p>
    <p>
      <Avatar className={classNames.square} />
      <Avatar className={classNames.rounded} />
    </p>
    <H2>Twitter</H2>
    <p>
      Twitter images are 16:9 aspect ratio with a maximum width of 1024 pixels
      and are presented with rounded corners.
    </p>
    <Teaser className={classNames.twitterInContext} />
    <Teaser className={classNames.twitter} />
    <H2>Instagram</H2>
    <p>Insta images are 1:1 aspect ratio with an optimal size of 1080.</p>
    <Teaser className={classNames.instagram} />
    <H2>LinkedIn</H2>
    <p>
      LinkedIn images are 1.91:1 aspect ratio with an optimal size of 1200 x
      627.
    </p>
    <Teaser className={classNames.linkedin} />
  </Layout>
);
export default BrandingPage;
