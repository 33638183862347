import React from "react";
import BrandingPage from "../components/BrandingPage";
import { useStaticQuery, graphql } from "gatsby";
import { SiteMetadataQueryResult, SnippetQueryResult } from "../graphql";

const BrandingPageHOC: React.FC<{}> = () => {
  const query = useStaticQuery<
    SiteMetadataQueryResult & SnippetQueryResult
  >(graphql`
    query {
      site {
        siteMetadata {
          description
          title
        }
      }
      file(sourceInstanceName: { eq: "snippets" }, name: { eq: "branding" }) {
        sourceInstanceName
        id
        name
        childMdx {
          body
        }
      }
    }
  `);
  return <BrandingPage query={query} />;
};
export default BrandingPageHOC;
