// extracted by mini-css-extract-plugin
export var avatar = "BrandingPage-module--avatar--s17an";
export var avatarImage = "BrandingPage-module--avatarImage--2OfD2";
export var square = "BrandingPage-module--square--2EyEF";
export var rounded = "BrandingPage-module--rounded--3Yhct";
export var teaser = "BrandingPage-module--teaser--2eGEr";
export var teaserHeading = "BrandingPage-module--teaserHeading--3rkdz";
export var teaserText = "BrandingPage-module--teaserText--2tWhD";
export var teaserSubhead = "BrandingPage-module--teaserSubhead--3INDT";
export var teaserImage = "BrandingPage-module--teaserImage--2uXCM";
export var twitter = "BrandingPage-module--twitter--3gc9s";
export var twitterInContext = "BrandingPage-module--twitterInContext--3rLD8";
export var instagram = "BrandingPage-module--instagram--1-LGv";
export var linkedin = "BrandingPage-module--linkedin--_HH9w";